import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TextField } from "@material-ui/core";
import ButtonPrimary from "../../../../shared/components/button-primary/button-primary";
import { login } from "../../services/back-office.services";
import "./login.scss";

const Login = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [user, setUser] = useState({ email: "", password: "" });
    const [showErrors, setShowErrors] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();
        if (user.email.length <= 0 || user.password.length <= 0) {
            setShowErrors(true);
        } else {
            login(user)
                .then((res) => {
                    if (res.status !== 200) throw new Error();
                    localStorage.setItem("sp-token", res.data.token);
                    localStorage.setItem("sp-user", JSON.stringify(res.data.user));
                })
                .catch(() => {
                    toast.error(t("login.error"), { autoClose: 15000 });
                })
                .finally(() => {
                    navigate("/admin");
                });
        }
    };

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    return (
        <div className="login">
            <form className="login__form" onSubmit={handleLogin}>
                {["email", "password"].map((field) => (
                    <TextField
                        key={field}
                        id={field}
                        type={field}
                        name={field}
                        value={user[field]}
                        label={t(`login.${field}`)}
                        onChange={handleChange}
                        error={showErrors && user[field].length <= 0}
                        required
                    />
                ))}
                <ButtonPrimary onClick={handleLogin} type="submit">
                    {t("login.button")}
                </ButtonPrimary>
            </form>
        </div>
    );
};

export default Login;
