import React from "react";
import { Trans } from "react-i18next";
import logo from "./assets/images/CapStudentConnexion.svg";
import shadeHeader from "../../../assets/images/shade-header.svg";
import image from "../../../assets/images/home-banner_img.jpg";

import "./home-banner.scss";

const HomeBanner = () => (

    <header className="home-banner">
        <div className="home-banner__content">
            <div className="home-banner__logo-title-container">
                <img src={logo} alt="Logo" className="home-banner__logo" />
                <Trans parent="h1" className="home-banner__title" i18nKey="home-banner.title" />
            </div>

            <img src={image} alt="" className="img" />

            <div className="home-banner__shade">
                <img src={shadeHeader} alt="" />
            </div>
        </div>
    </header>
);

export default HomeBanner;