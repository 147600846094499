import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import ButtonPrimary from "../../../../shared/components/button-primary/button-primary";
import dataProtection from "../../../../shared/assets/downloads/data-protection.pdf";
import { addCandidate } from "../../services/candidate.services";
import candidateFields from "./candidate-fields.json";
import "./join.scss";

const Join = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showErrors, setShowErrors] = useState(false);
    const [candidate, setCandidate] = useState({
        "contact-section": undefined,
        civility: "",
        "first-name": "",
        "last-name": "",
        mobile: "",
        email: "",
        "formation-section": undefined,
        "graduating-year": "",
        major: "",
        "school-name": "",
        "school-other": "",
        cv: "",
        "question-section": undefined,
        how: "",
        "how-other": "",
        why: "",
        extracurricular: "",
        actions: "",
        communications: "",
        tos: false,
        conditions: false,
    });

    const MOBILE_REGEX = /^\d{10}$/;

    const isValid = (key) => {
        const { required } = candidateFields[key];
        if (key === "mobile") {
            return MOBILE_REGEX.test(candidate[key]);
        }
        if (key === "tos" || key === "conditions") {
            return !required || (required && candidate[key]);
        }
        return !required || (required && candidate[key].length > 0);
    };

    const showField = (key) => {
        const { parent } = candidateFields[key];
        return !parent || candidate[parent.name] === parent.value;
    };

    const handleChange = (e) => {
        setCandidate({ ...candidate, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setCandidate({ ...candidate, [e.target.name]: e.target.files[0] });
    };

    const handleCheckboxChange = (e) => {
        setCandidate({ ...candidate, [e.target.value]: !candidate[e.target.value] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const invalidFields = Object.keys(candidate).filter((key) => !isValid(key) && showField(key));
        if (invalidFields.length > 0) {
            setShowErrors(true);
        } else {
            const {
                "school-name": sn,
                "school-other": so,
                how: h,
                "how-other": ho,
                "first-name": fn,
                "last-name": ln,
                "graduating-year": gy,
                ...results
            } = candidate;
            delete results["contact-section"];
            delete results["formation-section"];
            delete results["question-section"];
            addCandidate({
                first_name: fn,
                last_name: ln,
                graduating_year: gy,
                school: sn === candidateFields["school-other"].parent.value ? so : sn,
                how: h === candidateFields["how-other"].parent.value ? ho : h,
                ...results,
            })
                .then((res) => {
                    if (res.status !== 200) throw new Error();
                    navigate("/join/success");
                })
                .catch(() => {
                    toast.error(t("join.error"), { autoClose: 15000 });
                });
        }
    };

    const getFlatOptions = (i18nKey) => {
        if (i18n.exists(i18nKey)) {
            const options = t(i18nKey, { joinArrays: "|" }).split("|");
            return options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ));
        }
        return false;
    };

    const getGroupedOptions = (i18nKey) => {
        const numGroups = t(i18nKey, { joinArrays: "|" }).split("|").length;
        return [...Array(numGroups).keys()].map((group) => {
            const groupKey = `${i18nKey}.${group}`;
            if (i18n.exists(`${groupKey}.name`)) {
                return (
                    <optgroup key={t(`${groupKey}.name`)} label={t(`${groupKey}.name`)}>
                        {getFlatOptions(`${groupKey}.options`)}
                    </optgroup>
                );
            }
            return getFlatOptions(`${groupKey}.options`);
        });
    };

    const getRadioOptions = (i18nKey) => t(i18nKey, { returnObjects: true });

    const generateFields = Object.keys(candidate).map((key) => {
        const { field, required, helper, type, grouped, multiline, autocomplete } = candidateFields[key];

        const options = grouped ? getGroupedOptions(`join.form.${key}.options`) : getFlatOptions(`join.form.${key}.options`);

        if (field === "section") {
            return (
                <h3 className="join__form__section-title" key={key}>
                    {t(`join.form-section.${key}`)}
                </h3>
            );
        }

        return (
            <div key={key} className={`join__form__field join__form__field--${key}`}>
                {showField(key) && field === "text" && (
                    <TextField
                        key={key}
                        id={key}
                        name={key}
                        value={candidate[key]}
                        label={t(`join.form.${key}.label`)}
                        required={required}
                        onChange={handleChange}
                        type={type}
                        helperText={helper && t(`join.form.${key}.helper`)}
                        multiline={multiline}
                        variant={multiline ? "outlined" : "standard"}
                        autoComplete={autocomplete}
                        error={showErrors && !isValid(key)}
                        fullWidth
                        inputProps={{ maxLength: 32000 }}
                    />
                )}
                {showField(key) && field === "drop-down" && (
                    <FormControl key={key} required={required} error={showErrors && !isValid(key)}>
                        <InputLabel htmlFor={key}>{t(`join.form.${key}.label`)}</InputLabel>
                        <NativeSelect
                            value={candidate[key]}
                            onChange={handleChange}
                            inputProps={{
                                name: key,
                                id: key,
                            }}>
                            {options}
                        </NativeSelect>
                        {helper && <FormHelperText>{t(`join.form.${key}.helper`)}</FormHelperText>}
                    </FormControl>
                )}
                {showField(key) && field === "upload" && (
                    <label htmlFor={key}>
                        <ButtonPrimary type="upload" outlined>
                            {t(`join.form.${key}.label`)}
                        </ButtonPrimary>
                        <input id={key} type="file" accept="application/pdf" name={key} onChange={handleFileChange} hidden />
                        {candidate.cv && candidate.cv.name && <div className="cv__info">{candidate.cv.name}</div>}
                    </label>
                )}
                {showField(key) && field === "checkbox" && (
                    <FormControlLabel
                        control={<Checkbox checked={candidate[key]} onChange={handleCheckboxChange} value={key} required={required} />}
                        label={t(`join.form.${key}.label`)}
                        className={showErrors && !isValid(key) ? "join__form__field--error" : ""}
                    />
                )}
                {showField(key) && field === "radio" && (
                    <FormControl key={key} required={required} error={showErrors && !isValid(key)}>
                        <FormLabel component="legend" htmlFor={key}>
                            {t(`join.form.${key}.label`)}
                        </FormLabel>
                        <RadioGroup
                            className="join__form__radio-group"
                            aria-label="civility"
                            name="civility"
                            value={candidate[key]}
                            onChange={handleChange}>
                            {getRadioOptions(`join.form.${key}.options`).map((optionStr) => (
                                <FormControlLabel key={optionStr} value={optionStr} control={<Radio />} label={optionStr} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )}
            </div>
        );
    });

    return (
        <div className="join">
            <div className="join__wrapper">
                <div className="join__title join__center">
                    <Trans parent="h2" className="join__title__text" i18nKey="join.title" />
                </div>

                <form className="join__form" onSubmit={handleSubmit}>
                    {generateFields}
                    <Trans parent="span" className="join__label-mandatory" i18nKey="join.mandatory-field-label">
                        <strong />
                    </Trans>

                    <Trans i18nKey="join.disclaimer" className="join__form__field--disclaimer" parent="div">
                        <a href={dataProtection} target="_blank" rel="noopener noreferrer">
                            .
                        </a>
                    </Trans>

                    <div className="join__center join__form__field join__form__field--submit">
                        <ButtonPrimary onClick={handleSubmit} type="submit">
                            {t("join.form.submit.label")}
                        </ButtonPrimary>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Join;
