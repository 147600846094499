import React from "react";

const LinkedInLogo = () => (
    <span className="logo linkedin-logo">
        <svg viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="linkedin">
                <path id="Vector" d="M51.92 26.9608C51.92 41.0242 40.5203 52.4216 26.4576 52.4216C12.3949 52.4216 1 41.0242 1 26.9608C1 12.8974 12.3998 1.5 26.4625 1.5C40.5251 1.5 51.9249 12.8974 51.9249 26.9608H51.92Z" stroke="#0070AD" strokeWidth="2" />
                <path id="Vector_2" d="M16.263 20.9973H20.6411V35.0607H16.263V20.9973ZM18.4496 14.0093C19.8491 14.0093 20.9861 15.1456 20.9861 16.5393C20.9861 17.933 19.8539 19.0742 18.4496 19.0742C17.0453 19.0742 15.9131 17.9379 15.9131 16.5393C15.9131 15.1408 17.0453 14.0093 18.4496 14.0093Z" fill="#0070AD" />
                <path id="Vector_3" d="M23.3818 20.9974H27.5754V22.9204H27.6337C28.2168 21.8132 29.6405 20.6526 31.7738 20.6526C36.2005 20.6526 37.0169 23.5614 37.0169 27.3541V35.0656H32.6484V28.2282C32.6484 26.5965 32.6144 24.4987 30.3791 24.4987C28.1439 24.4987 27.76 26.276 27.76 28.1068V35.0608H23.3916V20.9974H23.3867H23.3818Z" fill="#0070AD" />
            </g>
        </svg>
    </span>
);

export default LinkedInLogo;