import React from "react";
import "./subsection.scss";
// eslint-disable-next-line import/no-unresolved
import ReactPlayer from "react-player";

const Subsection = ({ mediaType, mediaUrl, textTitle, textLink, link }) => (
    <section className="subsection-block">
        <div className="subsection-block__text">
            <h3 className="subsection-block__text__title">{textTitle}</h3>
            {link && <a href={link} className="btn-quaternary" target="_blank" rel="noreferrer">{textLink}</a>}
        </div>
        <div className="subsection-block__media">
            {mediaType === "video" && (
                <ReactPlayer
                    className='subsection-block__media__video'
                    url={mediaUrl}
                    controls
                    width="100%"
                    height="100%"
                />
            )}
            {mediaType === "image" && (
                <img src={mediaUrl} alt="Media" className="subsection-block__media__image" />
            )}
        </div>
    </section>
);

export default Subsection;
