import React from "react";

const YoutubeInLogo = () => (
    <span className="logo youtube-logo">
        <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="youtube">
                <path id="Vector" d="M52.8497 26.9608C52.8497 41.0242 41.4499 52.4216 27.3873 52.4216C13.3246 52.4216 1.9248 41.0242 1.9248 26.9608C1.9248 12.8974 13.3295 1.5 27.3921 1.5C41.4548 1.5 52.8546 12.8974 52.8546 26.9608H52.8497Z" stroke="#0070AD" strokeWidth="2" />
                <path id="Vector_2" d="M39.759 23.7314C39.759 20.7352 37.3293 18.3022 34.3312 18.3022H20.4532C17.4599 18.3022 15.0254 20.7303 15.0254 23.7314V30.1852C15.0254 33.1815 17.455 35.6144 20.4532 35.6144H34.3312C37.3245 35.6144 39.759 33.1863 39.759 30.1852V23.7314ZM31.5954 27.4415L25.3707 30.5203C25.1278 30.6514 24.2968 30.4766 24.2968 30.195V23.8771C24.2968 23.5954 25.1326 23.4158 25.3756 23.5615L31.333 26.8005C31.5808 26.9413 31.8432 27.3055 31.5954 27.4415Z" fill="#0070AD" />
            </g>
        </svg>
    </span>
);

export default YoutubeInLogo;