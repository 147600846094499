import http from "../../../../config/api/api.config";

const addCandidate = (candidate) => {
    const bodyFormData = new FormData();
    Object.keys(candidate).forEach((key) => bodyFormData.append(key, candidate[key]));
    return http.post("/candidates", bodyFormData);
};

const deleteCandidate = (id) => http.delete(`/candidates/${id}`);

const deleteAll = () => http.delete("/candidates");

const getAllCandidates = () => http.get("/candidates");

const getCandidateCv = (id) =>
    http.get(`/candidates/${id}/cv`, {
        responseType: "blob",
    });

const getCandidateXls = (id) => http.get(`/candidates/${id}`);

const downloadBlob = (blob, filename) => {
    if (!window.navigator.msSaveOrOpenBlob) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
};

export { addCandidate, getAllCandidates, getCandidateCv, getCandidateXls, deleteCandidate, deleteAll, downloadBlob };
