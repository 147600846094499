import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { deleteCandidate, downloadBlob, getAllCandidates, getCandidateCv, getCandidateXls } from "../../services/candidate.services";
import "./list-candidates.scss";

const ListCandidates = () => {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [candidates, setCandidates] = useState([]);
    const [change, setChange] = useState(false);

    useEffect(() => {
        getAllCandidates()
            .then((res) => {
                if (res.status !== 200) throw new Error();
                const orderedCandidates = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setCandidates(orderedCandidates);
                setLoaded(true);
            })
            .catch((err) => {
                toast.error(err.toString(), { autoClose: 15000 });
            });
    }, [change]);

    const handleDeleteCandidate = (id) => {
        deleteCandidate(id)
            .then((res) => {
                if (res.status !== 200) throw new Error();
                const newCandidates = candidates.filter((i) => i !== id);
                setCandidates(newCandidates);
                setChange(!change);
            })
            .catch((err) => {
                toast.error(err.toString(), { autoClose: 15000 });
            });
    };

    const handleChange = () => {
        setChange(!change);
    };

    const handleDownloadCV = (id, email) => {
        getCandidateCv(id)
            .then((res) => {
                if (res.status !== 200) throw new Error();
                const blob = new Blob([res.data], {
                    type: "application/pdf",
                });

                downloadBlob(blob, `cv_${email}.pdf`);
            })
            .catch((err) => {
                toast.error(err.toString(), { autoClose: 15000 });
            });
    };

    const handleExport = (id, email) => {
        getCandidateXls(id)
            .then((res) => {
                if (res.status !== 200) throw new Error();
                const rows = [Object.keys(res.data), Object.values(res.data)].map((e) => e.join(";")).join("\n");

                const blob = new Blob([rows], { type: "text/csv;charset=utf-8;" });

                downloadBlob(blob, `info_${email}.csv`);
                handleChange();
            })
            .catch((err) => {
                toast.error(err.toString(), { autoClose: 15000 });
            });
    };

    const columns = ["id", "first-name", "last-name", "email", "mobile", "school-name", "date", "downloaded", "cv", "csv", "delete"];

    return (
        <div className="list-candidates">
            {loaded && candidates.length > 0 && (
                <div className="list-candidates__table">
                    {columns.map((column) => (
                        <div key={column} className="list-candidates__table__item list-candidates__table__item--head">
                            {t(`list-candidates.${column}`)}
                        </div>
                    ))}
                    {candidates.map((candidate) => {
                        const { id, email } = candidate;
                        return (
                            <React.Fragment key={id}>
                                {Object.keys(candidate).map((field) => {
                                    if (field === "created_at")
                                        return (
                                            <div key={field} className="list-candidates__table__item">
                                                {new Date(candidate[field]).toLocaleDateString("fr-FR")}
                                            </div>
                                        );
                                    if (field === "downloaded")
                                        return (
                                            <div key={field} className="list-candidates__table__item list-candidates__table__item--icon">
                                                {candidate[field] && <div className="icon-check" />}
                                            </div>
                                        );
                                    if (field === "cv" && candidate[field] !== "")
                                        return (
                                            <div key={field} className="list-candidates__table__item list-candidates__table__item--icon">
                                                <button onClick={() => handleDownloadCV(id, email)} type="button">
                                                    <div className="icon-file-pdf-o" />
                                                </button>
                                            </div>
                                        );
                                    if(field ==="id")
                                        return (
                                            <div key={field} className="list-candidates__table__item">
                                                {candidates.indexOf(candidate) + 1}
                                            </div>
                                        );
                                    return (
                                        <div key={field} className="list-candidates__table__item">
                                            {candidate[field]}
                                        </div>
                                    );
                                })}
                                <div className="list-candidates__table__item list-candidates__table__item--icon">
                                    <button onClick={() => handleExport(id, email)} type="button">
                                        <div className="icon-download" />
                                    </button>
                                </div>
                                <div className="list-candidates__table__item list-candidates__table__item--icon">
                                    <button onClick={() => handleDeleteCandidate(id)} type="button">
                                        <div className="icon-trash" />
                                    </button>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ListCandidates;
