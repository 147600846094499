import React from "react";

const FacebookLogo = () => (
    <span className="logo facebook-logo">
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="facebook">
                <path id="Vector" d="M52.9996 27C52.9996 41.0851 41.5169 52.5 27.3519 52.5C13.1869 52.5 1.7041 41.0851 1.7041 27C1.7041 12.9149 13.1918 1.5 27.3519 1.5C41.512 1.5 52.9996 12.9149 52.9996 27Z" stroke="#0070AD" strokeWidth="2" />
                <path id="Vector_2" d="M29.0117 39.0521V27.608H32.854L33.5833 22.8757H29.0166V19.8019C29.0166 18.5082 29.6578 17.2436 31.7038 17.2436H33.7791V13.2165C33.7791 13.2165 31.8946 12.9004 30.0934 12.9004C26.3344 12.9004 23.8724 15.1668 23.8724 19.2717V22.8806H19.6875V27.6129H23.8724V39.057H29.0215H29.0166L29.0117 39.0521Z" fill="#0070AD" />
            </g>
        </svg>
    </span>
);

export default FacebookLogo;
