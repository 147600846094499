import React from "react";
import { Link } from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import addClassCond from "../../utils/add-class-cond";
import "./button-primary.scss";

const ButtonPrimary = (props) => {
    const { children, path, onClick = () => {}, className = "", reverse, compact, outlined, secondary, tertiary, type = "link" } = props;

    let btnClasses = "button-primary";
    btnClasses = addClassCond(btnClasses, className, className);
    btnClasses = addClassCond(btnClasses, reverse, "button-primary--reverse");
    btnClasses = addClassCond(btnClasses, compact, "button-primary--compact");
    btnClasses = addClassCond(btnClasses, outlined, "button-primary--outlined");
    btnClasses = addClassCond(btnClasses, secondary, "button-primary--secondary");
    btnClasses = addClassCond(btnClasses, tertiary, "button-primary--tertiary");

   

    if (type === "link")
        return (
            <Link to={path} className={btnClasses}>
                {children}
            </Link>
        );

    if (type === "upload")
        return (
            <div className={btnClasses}>
                <ButtonBase component="span">{children}</ButtonBase>
            </div>
        );

    return (
        <button type="submit" onClick={onClick} className={btnClasses}>
            {children}
        </button>
    );
};

export default ButtonPrimary;
