import React from "react";
import { useTranslation } from "react-i18next";
import addClassCond from "../../../../../shared/utils/add-class-cond";
import "./card-testimony.scss";

const CardTestimony = (props) => {
    const { profile, name, job, text, className = "" } = props;
    const { t } = useTranslation();

    return (
        <div className={addClassCond("card-testimony", !!className, className)}>
            <div className="card-testimony__content-wrapper">
                <img className="card-testimony__profile" src={profile} alt="" />
                <p className="card-testimony__name">{t(name)}</p>
                <p className="card-testimony__job">{t(job)}</p>
            </div>
            <p className="card-testimony__text">{t(text)}</p>
        </div>
    );
};

export default CardTestimony;