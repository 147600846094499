import React from "react";
import { useTranslation } from "react-i18next";
import "./subsection-secondary.scss";

const SubsectionSecondary = ({ title, text, buttonText, buttonLink, imageSrc, imageAlt }) => {

    const { t } = useTranslation();

    return(
        <section className="subsection-secondary">
            <div className="subsection-secondary__text">
                <h3 className="subsection-secondary__text__title">{t(title)}</h3>
                <p>{t(text)}</p>
                <a className="btn-tertiary" href={t(buttonLink)} target="_blank" rel="noopener noreferrer">
                    {t(buttonText)}
                </a>
            </div>
            <div className="subsection-secondary__media">
                <img src={imageSrc} alt={t(imageAlt)} className="subsection-secondary__media__image" />
            </div>
        </section>
    )

};

export default SubsectionSecondary;
