import React from "react";

const InstagramLogo = () => (
    <span className="logo instagram-logo">
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="instagram">
                <path id="Vector" d="M52.7042 26.9656C52.7042 41.029 41.3044 52.4264 27.2417 52.4264C13.1791 52.4264 1.7793 41.029 1.7793 26.9656C1.7793 12.9022 13.1839 1.5 27.2417 1.5C41.2995 1.5 52.7042 12.8974 52.7042 26.9608V26.9656Z" stroke="#0070AD" strokeWidth="2" />
                <path id="Vector_2" d="M34.263 18.448C33.4077 18.448 32.708 19.1473 32.708 20.002C32.708 20.8566 33.4077 21.5559 34.263 21.5559C35.1182 21.5559 35.8179 20.8566 35.8179 20.002C35.8179 19.1473 35.1182 18.448 34.263 18.448Z" fill="#0070AD" />
                <path id="Vector_3" d="M27.3531 20.439C23.7573 20.439 20.8271 23.3672 20.8271 26.9608C20.8271 30.5543 23.7524 33.4826 27.3531 33.4826C30.9538 33.4826 33.8791 30.5543 33.8791 26.9608C33.8791 23.3672 30.9538 20.439 27.3531 20.439ZM27.3531 31.1468C25.0498 31.1468 23.1742 29.2674 23.1742 26.9656C23.1742 24.6638 25.0498 22.7845 27.3531 22.7845C29.6564 22.7845 31.5321 24.6638 31.5321 26.9656C31.5321 29.2674 29.6564 31.1468 27.3531 31.1468Z" fill="#0070AD" />
                <path id="Vector_4" d="M32.538 40.2183H21.9594C17.5667 40.2183 14 36.649 14 32.259V21.6775C14 17.2875 17.5667 13.7183 21.9594 13.7183H32.538C36.9308 13.7183 40.4975 17.2875 40.4975 21.6775V32.259C40.4975 36.649 36.9308 40.2183 32.538 40.2183ZM21.9546 16.2095C18.9419 16.2095 16.4879 18.6618 16.4879 21.6775V32.259C16.4879 35.2698 18.937 37.7271 21.9546 37.7271H32.5332C35.5459 37.7271 37.9998 35.2747 37.9998 32.259V21.6775C37.9998 18.6667 35.5507 16.2095 32.5332 16.2095H21.9546Z" fill="#0070AD" />
            </g>
        </svg>
    </span>
);

export default InstagramLogo;
