import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SocialMedia from "./social-media/social-media";
import participation from "../../assets/downloads/cp-2024.pdf";
import tos from "../../assets/downloads/cgu-2024.pdf";
import logo from "../../assets/images/logo-capgemini.svg";
import "./footer.scss";

const Footer = () => {
    const { t } = useTranslation();
    const contactEmail = "capgeministudentconnection.fr@capgemini.com";

    const legal = [
        { name: "legal", download: participation },
        { name: "tos", download: tos },
    ];


    return (

        <footer className="footer">
            <div className="footer__brand">
                <Link to="/" className="logo">
                    <img src={logo} alt={t("header.brand")} />
                </Link>
                <p className="copyright">{t("footer.copyright")}</p>
            </div>
            <div className="footer__legal">
                {legal.map((link) => (
                    <a href={link.download} key={link.name} className="footer__legal__link" target="_blank" rel="noopener noreferrer">
                        {t(`footer.${link.name}`)}
                    </a>
                ))} 
            </div>
            <div className="footer__contact">
                <div className="email">
                    <p className="title">{t("footer.contact")}</p>
                    <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </div>
                <SocialMedia className="social-links" />
            </div>

        </footer>
    );
};

export default Footer;
