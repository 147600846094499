/**
 *
 * @param {string} base The base string
 * @param {boolean} cond if true, concatenate base and strToAdd separate by a space.
 * @param {string} strToAdd The string you want to add (conditionally).
 */
const addClassCond = (base, cond, strToAdd) => {
    if (cond) {
        return `${base} ${strToAdd}`;
    }
    return base;
};

export default addClassCond;
