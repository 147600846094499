import axios from "axios";

let baseURL;
if(process.env.NODE_ENV !=="production"){
    baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
}else{
    baseURL = process.env.REACT_APP_BASE_URL;
}

const http = axios.create({
    baseURL,
    timeout: 30000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("sp-token")}`,
    },
});

http.interceptors.request.use(
    (config) => {
        const { headers } = config;
        return { ...config, headers: { ...headers, Authorization: `Bearer ${localStorage.getItem("sp-token")}` } };
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    res => res,
    error => {
        const { status } = error.response;
        if (status && status === 401){
            localStorage.clear();
            window.location = "/login";
        }
        return Promise.reject(error);
    }
);

export default http;
