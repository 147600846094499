import React from "react";
import { Trans, useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import ReactPlayer from "react-player";
import Card from "../../components/card/card";
import Subsection from "../../components/subsection/subsection";
import SwiperComponent from "../../../../shared/components/swiper-template/swiper-template";
import separator from "../../assets/images/separateur.svg";
import SubsectionSecondary from "../../components/subsection-secondary/subsection-secondary"
import SwiperComponentSecondary from "../../../../shared/components/swiper-template-secondary/swiper-template-secondary";
import imageMetier from "../../assets/images/metiers.png";
import shadeHeader from "../../assets/images/shade2.svg";
import imageSwiperSecondary1 from "../../assets/swipersecondary/Communaute-de-femmes-visuel.jpeg";
import imageSwiperSecondary2 from "../../assets/swipersecondary/Capgemini_CSR-Digital-Inclusion.jpg";
import imageSwiperSecondary3 from "../../assets/swipersecondary/Capgemini_Digital-inclusion_Redi_Women.jpg";
import imageSwiperSecondary4 from "../../assets/swipersecondary/visuel-impact-together-week.png";

import "./group.scss";

const videoUrl = "https://www.youtube.com/watch?v=JffOAPu4ptw&t=15s";

const CardList = [
    {
        title: "group.card.strategy.title",
        text: "group.card.strategy.content",
        id: "strategy",
    },
    {
        title: "group.card.technology.title",
        text: "group.card.technology.content",
        id: "technology",
    },
    {
        title: "group.card.engineering.title",
        text: "group.card.engineering.content",
        id: "engineering",
    },
    {
        title: "group.card.operations.title",
        text: "group.card.operations.content",
        id: "operations",
    },
];

const SubsectionSecondaryList = [
    {
        title: "group.subsectionSecondary.content1.title",
        text: "group.subsectionSecondary.content1.text",
        imageSrc: imageSwiperSecondary1,
        id: "content1",
    },
    {
        title: "group.subsectionSecondary.content2.title",
        text: "group.subsectionSecondary.content2.text",
        imageSrc: imageSwiperSecondary2,
        id: "content2",
    },
    {
        title: "group.subsectionSecondary.content3.title",
        text: "group.subsectionSecondary.content3.text",
        imageSrc: imageSwiperSecondary3,
        id: "content3",
    },
    {
        title: "group.subsectionSecondary.content4.title",
        text: "group.subsectionSecondary.content4.text",
        imageSrc: imageSwiperSecondary4,
        id: "content4",
    },
];

const Group = () => {
    const { t } = useTranslation();

    return (
        <div className="group">
            <div className="intro">
                <div className="group__intro-wrapper">
                    <div className="group__intro">
                        <Trans className="group__title" parent="h1" i18nKey="group.title" />
                        <div className="group__intro__content">
                            <p className="group__intro__text">{t("group.intro.content")}</p>
                            <div className="get-future">
                                <p>{t("group.intro.linkcontent")}</p>
                                <span>
                                    <img src={separator} className="separator" alt="" />
                                </span>
                                <a href="https://www.capgemini.com/fr-fr/" target="_blank" rel="noreferrer">www.capgemini.fr</a>
                            </div>
                        </div>
                    </div>
                    <div className="group__video">
                        <ReactPlayer
                            className='subsection-block__media__video'
                            url={videoUrl}
                            controls
                            width="100%"
                            height="100%"
                        />
                    </div>

                </div>
            </div>

            <SwiperComponent className="swiper-cards">
                {CardList.map((card) => (
                    <Card
                        className={`home__card-list__item home__card-list__item--${card.id}`}
                        title={card.title}
                        text={card.text}
                        key={card.id}
                    />
                ))}
            </SwiperComponent>

            <Subsection
                mediaType="image"
                mediaUrl={imageMetier}
                textTitle={
                    <>
                        Envie de découvrir nos métiers ?<br /><span style={{ fontStyle: "italic", fontWeight: 400 }}>C’est par ici !</span>
                    </>
                }
                textLink="Nos métiers"
                link="https://www.capgemini.com/fr-fr/carrieres/parcours-professionnels/etudiants-diplomes/stage-et-alternance/quels-metiers-en-stage-ou-en-alternance/"
            />

            <div className="home-swiper-component-secondary">
                <SwiperComponentSecondary className="swiper-secondary">
                    {SubsectionSecondaryList.map((subsectionSecondary) => (
                        <SubsectionSecondary
                            key={subsectionSecondary.id}
                            title={subsectionSecondary.title}
                            text={subsectionSecondary.text}
                            buttonText={subsectionSecondary.buttonText}
                            buttonLink={subsectionSecondary.buttonLink}
                            imageSrc={subsectionSecondary.imageSrc}
                        />
                    ))}
                </SwiperComponentSecondary>
                <img src={shadeHeader} alt="Logo" className="shade" />
            </div>

        </div>
    );
};

export default Group;
