import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../../../../config/authentication/auth-context";
import { logout } from "../../services/back-office.services";

const Logout = () => {
    const { isAuth, unAuthenticate } = useContext(AuthContext);

    useEffect(() => {
        if (isAuth) {
            logout().finally(() => {
                unAuthenticate();
            })
        }
    }, [isAuth, unAuthenticate]);

    return <Navigate to="/" replace />;
};

export default Logout;
