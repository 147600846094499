import React, { createContext, useEffect, useMemo, useState } from "react";
import { getCurrentUser } from "../../app/entities/auth/services/back-office.services";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);

    const unAuthenticate = () => {
        localStorage.removeItem("sp-token");
        localStorage.removeItem("sp-user");
        setIsAuth(false);
    };

    const getUserConnected = () => {
        const localUser = localStorage.getItem("sp-user");
        const localToken = localStorage.getItem("sp-token");
        if (localUser && localToken) {
            getCurrentUser().then(() => {
                setIsAuth(true);
            }).catch(() => {
                unAuthenticate();
            })
        }
    };

    useEffect(getUserConnected, []);

    return (
        <AuthContext.Provider value={useMemo(() => ({ isAuth, unAuthenticate }), [isAuth])}>
            {children}
        </AuthContext.Provider>
    );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };
