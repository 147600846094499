import React from "react";

const TiktokInLogo = () => (
    <span className="logo tiktok-logo">
        <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="tiktok">
                <path id="Vector" d="M52.7794 26.9608C52.7794 41.0242 41.3796 52.4216 27.3169 52.4216C13.2543 52.4216 1.85449 41.0242 1.85449 26.9608C1.85449 12.8974 13.2543 1.5 27.3169 1.5C41.3796 1.5 52.7794 12.8974 52.7794 26.9608Z" stroke="#0070AD" strokeWidth="2" />
                <path id="Vector_2" d="M37.8954 24.6009C37.6914 24.6203 37.4921 24.63 37.288 24.63C35.0576 24.63 32.9827 23.5083 31.7631 21.6435V31.8123C31.7631 35.9594 28.4005 39.3247 24.2507 39.3247C20.1009 39.3247 16.7383 35.9594 16.7383 31.8123C16.7383 27.6651 20.1009 24.2998 24.2507 24.2998C24.411 24.2998 24.5617 24.3095 24.7172 24.3241V28.0293C24.5617 28.0099 24.411 27.9856 24.2507 27.9856C22.1369 27.9856 20.4167 29.7047 20.4167 31.822C20.4167 33.9393 22.1369 35.6583 24.2507 35.6583C26.3645 35.6583 28.2401 33.9927 28.2401 31.8705L28.279 14.6069H31.8214C32.1567 17.7829 34.7126 20.2643 37.9052 20.4974V24.6106H37.9003L37.8954 24.6009Z" fill="#0070AD" />
            </g>
        </svg>
    </span>
);

export default TiktokInLogo;