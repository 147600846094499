import React from "react";
import { useTranslation } from "react-i18next";
import successImg from "../../assets/images/icon-success.svg";
import "./join-success.scss";

const Join = () => {
    const { t } = useTranslation();

    return (
        <div className="join-success">
            <div className="join-success__wrapper">
                <div className="join-success__message">
                    <img className="join-success__image" src={successImg} alt="" />
                    <h1 className="join-success__title">{t("join-success.title")}</h1>
                    <p className="join-success__text">{t("join-success.text")}</p>
                    <p className="join-success__text join-success__text--end">{t("join-success.end")}</p>
                </div>
            </div>
        </div>
    );
};

export default Join;
