import React from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../../../shared/components/button-primary/button-primary";
import "./admin.scss";

const Admin = () => {
    const { t } = useTranslation();
    return (
        <div className="admin">
            <div className="admin__dashboard">
                <ButtonPrimary className="admin__dashboard__item" type="link" path="/admin/candidates">
                    <div className="icon-profile" />
                    {t("admin.candidates")}
                </ButtonPrimary>
                <ButtonPrimary className="admin__dashboard__item" type="link" path="/logout">
                    {t("admin.logout")}
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default Admin;
