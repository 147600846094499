import React, { useEffect, useRef } from "react";
import Swiper from "swiper";
// eslint-disable-next-line import/no-unresolved
import { Navigation, Pagination } from "swiper/modules";
// eslint-disable-next-line import/no-unresolved
import "swiper/swiper-bundle.css";
import "./swiper-template-secondary.scss";

Swiper.use([Navigation, Pagination]);

const SwiperNavigation = () => (
    <>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
    </>
);

const SwiperPagination = () => <div className="swiper-pagination" />;

const SwiperComponentSecondary = ({ children, className }) => {
    const swiperRef = useRef(null);


    useEffect(() => {
        if (swiperRef.current) {
            // eslint-disable-next-line no-new
            new Swiper(swiperRef.current, {
                slidesPerView: "1",
                watchSlidesProgress: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
            });
        }
    }, []);

    return (
        <div className={`swiper-container swiper-template ${className}`} ref={swiperRef}>
            <div className="swiper-wrapper">
                {React.Children.map(children, (child, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="swiper-slide" key={index}>
                        {child}
                    </div>
                ))}
            </div>
            <div className="swiper-controls">
                <SwiperNavigation />
                <SwiperPagination />
            </div>
        </div>
    );
};

export default SwiperComponentSecondary;