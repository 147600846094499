import React from "react";
import FacebookLogo from "./logos/facebook-logo";
import InstagramLogo from "./logos/instagram-logo";
import LinkedInLogo from "./logos/linkedin-logo";
import YoutubeInLogo from "./logos/youtube-logo";
import TiktokInLogo from "./logos/tiktok-logo";
import "./social-media.scss";

const SocialMedia = (props) => {
    const { className = "" } = props;
    return (
        <ul className={`social-media ${className}`}>
            <li>
                <a href="https://www.linkedin.com/company/capgemini" className="social-media__logo linkedin" target="_blank" rel="noreferrer">
                    <LinkedInLogo />
                </a>
            </li>
            <li>
                <a href="https://www.jobteaser.com/fr/companies/capgemini" className="social-media__logo jobteaser" target="_blank" rel="noreferrer">
                    <YoutubeInLogo />
                </a>
            </li>
            <li>
                <a href="https://www.jobteaser.com/fr/companies/capgemini" className="social-media__logo jobteaser" target="_blank" rel="noreferrer">
                    <TiktokInLogo />
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/capgeminifr/?hl=fr" className="social-media__logo instagram" target="_blank" rel="noreferrer">
                    <InstagramLogo />
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/capgeminifrance/" className="social-media__logo facebook" target="_blank" rel="noreferrer">
                    <FacebookLogo />
                </a>
            </li>
        </ul>
    );
};

export default SocialMedia;
