import React, { useState } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import "./faq.scss";

const FaqIcon = () => (
    <svg className="faq-toggle__icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g fill="#0070AD" fillRule="evenodd" clipRule="evenodd">
            <path d="M29.4142 9.92172C30.1953 10.7028 30.1953 11.9691 29.4142 12.7502L16.5 25.6644L3.58579 12.7502C2.80474 11.9691 2.80474 10.7028 3.58579 9.92172C4.36684 9.14068 5.63317 9.14068 6.41422 9.92172L16.5 20.0075L26.5858 9.92172C27.3668 9.14068 28.6332 9.14068 29.4142 9.92172Z" />
        </g>
    </svg>
);

const Faq = () => {
    const { t } = useTranslation();
    const contentList = t("faq.content", { returnObjects: true });

    const [faqToggle, setFaqToggle] = useState(new Array(contentList.length).fill(false));

    const toggle = (pos) => {
        const newToggle = faqToggle.map((state, i) => (i === pos ? !state : state));
        setFaqToggle(newToggle);
    };

    return (
        <div className="faq">
            <Trans className="faq__title" parent="h1" i18nKey="faq.title" />
            <dl className="faq__wrapper">
                {contentList.map((content, pos) => (
                    <React.Fragment key={content.question}>
                        <button
                            className="faq-toggle__question"
                            type="button"
                            aria-expanded={faqToggle[pos]}
                            aria-controls={pos}
                            onClick={() => toggle(pos)}>
                            <dt className="faq-toggle__question__title">
                                <span className="faq-toggle__question__title__text">
                                    {pos + 1}. {content.question}
                                </span>
                                <FaqIcon />
                            </dt>

                            <dd
                                className={classNames("faq-toggle__answer", {
                                    "faq-toggle__answer--hidden": !faqToggle[pos],
                                })}
                                id={pos}>
                                <Trans>{t(`faq.content.${pos}.answer`)}</Trans>
                            </dd>
                        </button>
                    </React.Fragment>
                ))}
            </dl>
        </div>
    );
};

export default Faq;
