import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "./card.scss";

const Card = (props) => {
    const { title, text, className = "" } = props;
    const { t } = useTranslation();

    return (
        <div className={`card ${className}`}>
            <div className="card__wrapper">
                <h2 className="card__title">{t(title)}</h2>
                <Trans parent="div" className="card__content" i18nKey={text} />
            </div>
        </div>
    );
};

export default Card;
